<template>
  <el-main>
    <div style="margin-bottom:10px;">
      <router-link to="/goods/AddType"><el-button type="primary" size="small">添加分类</el-button></router-link>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品分类：">
        <el-input size="small" v-model="name" placeholder="请输入搜索分类"></el-input>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select v-model="is_show" @change="showValChange" size="small">
          <el-option v-for="item in showOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" @click="searchType" size="small">搜索</el-button>
        <el-button class="boderBlue" size="small" @click="exportGoodsClass">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="goodsClass" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="name" label="分类名称" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row.grade == '一级' ? 'bold' : '' }">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="分类等级" align="center">
        <template v-slot="{ row }">
          <span :style="{ 'font-weight': row.grade == '一级' ? 'bold' : '' }">{{ row.grade }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="分类ID" align="center"></el-table-column>
      <el-table-column prop="address" label="组内权重" width="120">
        <template #header>
          <div class="edit">
            <span>组内权重</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)" v-model="scope.row.rank"></el-input>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="address" label="商品数量"></el-table-column> -->
      <el-table-column prop="picture" label="图片" align="center">
        <template slot-scope="scope">
          <el-image style="width: 60px; height: 60px" :src="scope.row.picture ? scope.row.picture : require('@/assets/default-img.png')"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0" @change="editChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="moveGoods(scope.row)" type="text" size="small">转移商品</el-button> -->
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-dialog class="move" title="转移商品" :visible.sync="dialogVisible" width="40%" @close="callbackClose">
      <div class="item">
        <span>原商品分类：{{ moveClass_name }}</span>
      </div>
      <div class="item">
        <span>转移到目标商品分类：</span>
        <el-cascader v-model="classTarget_id" :options="goodsClass" @change="handleClassChange"></el-cascader>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = !1">取 消</el-button>
        <el-button type="primary" @click="moveGoodsSubmit">确 定</el-button>
      </span>
    </el-dialog> -->
  </el-main>
</template>

<script>
import config from '@/util/config';
export default {
  data() {
    return {
      moveClass_id: '', //要移动的分类id
      moveClass_name: '',
      classTarget_id: '', //要移动到目标的分类id
      dialogVisible: !1,
      showOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '否',
        },
        {
          value: 2,
          label: '是',
        },
      ],
      is_show: 0,
      name: '',
    };
  },
  computed: {
    goodsClass() {
      return this.$store.getters.goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList', {
      is_show: this.is_show,
      name: this.name,
    });
  },
  methods: {
    exportGoodsClass() {
      this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
        if (res.code == 0) {
          let path = config.baseurl + '/' + res.result;
          let a = document.createElement('a');
          a.href = path;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editChange(row) {
      this.$axios
        .post(this.$api.goods.editType, {
          id: row.id,
          parent_id: row.parent_id,
          name: row.name,
          picture: row.picture,
          rank: row.rank,
          is_show: row.is_show,
        })
        .then(res => {
          if (res.code == 0) {
            this.$store.dispatch('goods/getTypeList', {
              is_show: this.is_show,
              name: this.name,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showValChange(val) {
      this.is_show = Number(val);
    },
    //搜索分类
    searchType() {
      this.$store.dispatch('goods/getTypeList', {
        is_show: this.is_show,
        name: this.name,
      });
    },
    cancelSearch() {
      this.name = '';
      this.is_show = 0;
      this.$store.dispatch('goods/getTypeList', {
        is_show: this.is_show,
        name: this.name,
      });
    },
    //转移商品
    moveGoods(row) {
      this.moveClass_id = row.id;
      this.moveClass_name = row.name;
      this.classTarget_id = '';
      this.dialogVisible = !0;
    },
    //提交转移商品
    moveGoodsSubmit() {
      if (!this.classTarget_id)
        return this.$message({
          message: '请选择要转移目标的分类',
          type: 'warning',
        });
      this.$axios
        .post(this.$api.goods.shiftGoods, {
          id: this.moveClass_id,
          classify_id: this.classTarget_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.dialogVisible = !1;
            this.$confirm('商品转移成功', '提示', {
              confirmButtonText: '确定',
              type: 'success',
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleClassChange(val) {
      val.length == 1 ? (this.classTarget_id = val[0]) : (this.classTarget_id = val[1]);
    },
    //编辑分类
    edit(row) {
      this.$router.push({
        path: '/goods/editGoodsType',
        query: {
          info: JSON.stringify(row),
        },
      });
    },
    //删除分类
    remove(row) {
      this.$confirm('确认删除此商品分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.goods.delType, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              let goodsClass = [...this.goodsClass];
              goodsClass.splice(row.index, 1);
              this.$store.commit('goods/getTypeList', goodsClass);
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    flex: 1;
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  .edit {
    display: flex;
    align-items: center;
    span {
      margin-right: 5px;
    }
    i {
      font-size: 17px;
    }
  }
  .move .item {
    display: flex;
    align-items: center;
    padding-left: 30px;
    margin-bottom: 20px;
  }
}
/deep/ .el-table__expand-icon--expanded {
  // 这是点击后的旋转角度
  transform: rotate(0deg);
}
/deep/.el-table__expand-icon {
  .el-icon-arrow-right:before {
    //这是收起图标
    content: '\e723';
    font-size: 16px;
  }
}
/deep/ .el-table__expand-icon--expanded {
  .el-icon-arrow-right:before {
    // 这是展开图标
    content: '\e722';
    font-size: 16px;
  }
}
</style>
